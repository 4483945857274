"use client";

import Link from "next/link";

import clsx from "clsx";

import Brands from "shared-lib/Brands";
import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import { useCustomExperiment } from "shared-lib/helpers/customStatSigHooks";
import { brand } from "shared-lib/helpers/envFunctions";
import {
  TRACKING_CATEGORIES,
  TRACKING_EVENTS,
} from "shared-lib/helpers/statsigFunctions";

import FacebookIcon from "shared-components/components/icons/social/Facebook";
import InstagramIcon from "shared-components/components/icons/social/Instagram";
import PinterestIcon from "shared-components/components/icons/social/Pinterest";
import TikTokIcon from "shared-components/components/icons/social/TikTok";

import { GlobalActionTypes, useGlobalState } from "@/context/globalState";
import PrivacyChoices from "@/components/icons/privacyChoices";

const curYear = new Date().getFullYear();

const navigation = {
  main: [
    { name: "Privacy Policy", href: "/privacy-policy", id: "privacy-policy" },
    { name: "Do Not Sell My Personal Information", href: "/privacy-policy", id: "do-not-sell-privacy-policy" },
    { name: "Terms of Use", href: "/terms", id: "terms-of-use" },
    { name: "Your Privacy Choices", href: "", id: "privacy-choices" },
  ],
  social: [
    {
      name: "Facebook",
      href: brand.facebook,
      icon: FacebookIcon,
      classes: "hover:fill-facebook-blue",
      onClick: () => {
        gtag.event({
          action: TRACKING_EVENTS.social_media_button_click,
          category: TRACKING_CATEGORIES.interaction,
          label: "facebook",
        });
        ClickServer.track(TRACKING_EVENTS.social_media_button_click, {
          link: "facebook",
        });
      },
    },
    {
      name: "Pinterest",
      href: brand.pinterest,
      icon: PinterestIcon,
      classes: "hover:fill-pinterest-red",
      onClick: () => {
        gtag.event({
          action: TRACKING_EVENTS.social_media_button_click,
          category: TRACKING_CATEGORIES.interaction,
          label: "pinterest",
        });
        ClickServer.track(TRACKING_EVENTS.social_media_button_click, {
          link: "pinterest",
        });
      },
    },
    {
      name: "Instagram",
      href: brand.id !== Brands.bigrecipe ? brand.instagram : "",
      icon: InstagramIcon,
      classes: "hover:fill-instagram-pink",
      onClick: () => {
        gtag.event({
          action: TRACKING_EVENTS.social_media_button_click,
          category: TRACKING_CATEGORIES.interaction,
          label: "instagram",
        });
        ClickServer.track(TRACKING_EVENTS.social_media_button_click, {
          link: "instagram",
        });
      },
    },
    {
      name: "TikTok",
      href: brand.tiktok,
      icon: TikTokIcon,
      classes: "hover:fill-tiktok-blue",
      onClick: () => {
        gtag.event({
          action: TRACKING_EVENTS.social_media_button_click,
          category: TRACKING_CATEGORIES.interaction,
          label: "tiktok",
        });
        ClickServer.track(TRACKING_EVENTS.social_media_button_click, {
          link: "tiktok",
        });
      },
    },
  ],
};

export default function Footer({ className = "" }) {
  const [, dispatch] = useGlobalState();

  useCustomExperiment("ezoic_ad_tracking");

  return (
    <footer
      className={clsx(
        className,
        "bg-theme-primary px-8 pb-32 pt-8 text-sm print:hidden",
      )}
    >
      <div className="mx-auto max-w-7xl overflow-hidden px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex justify-center space-x-6">
          {navigation.social.map(
            (item) =>
              item.href && (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  className="transition-all duration-75 hover:scale-125"
                  onClick={item.onClick}
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon
                    className={clsx(
                      item.classes,
                      "h-9 rounded-full fill-white transition-colors duration-300",
                    )}
                    aria-hidden="true"
                  />
                </a>
              ),
          )}
        </div>
        <nav
          className="flex flex-col justify-center pt-4 text-white md:flex-row"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2 text-center">
              <Link
                id={item.id}
                onClick={(e) => {
                  if (e.currentTarget.id === "privacy-choices") {
                    if (window.OneTrust) {
                      window.OneTrust?.ToggleInfoDisplay()
                    }
                    e.preventDefault();
                  }
                  dispatch({
                    type: GlobalActionTypes.RESET_VISITED_PAGE,
                  });
                }}
                href={item.href}
                className="text-base underline hover:text-blue-400"
              >
                {item.id === "privacy-choices" && brand.id === "cookingprofessionally" && (<><PrivacyChoices className="inline-block mr-2" />{item.name}</>)}
                {item.id !== "privacy-choices" && item.name}
              </Link>
            </div>
          ))}
        </nav>
        <p className="mt-8 text-center text-base text-white">
          {`© ${curYear} by ${brand.name}`}
        </p>
      </div>
    </footer>
  );
}
