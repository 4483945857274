"use client";

import { brand, getOneTrustDomainScript } from "shared-lib/helpers/envFunctions";
import Script from "next/script";

const OneTrust = () => {
  if (brand.id !== "cookingprofessionally") {
    return null;
  }

  return (
    <>
      <Script
        id="onetrust-script"
        strategy="afterInteractive"
        data-domain-script={getOneTrustDomainScript()}
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
      />
      <Script id="onetrust-init" strategy="afterInteractive">
        {`
          function OptanonWrapper() { }
        `}
      </Script>
    </>
  );
};

export default OneTrust;
